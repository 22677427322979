import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../css/swiper.css";
import "../../css/common.css";
import "../../css/rally.css";
import "../../css/sfgj.css";
import { Link } from "react-router-dom";
import useRequest from "../../api/api";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
const AlgorithmTools: React.FC = () => {
  const url = `/api/algorithm/list?page_size=99`;
  const [data, setData] = useState([]);
  const script7 = document.createElement("script");
  const onSuccess = (res: any) => {
    setData(res.data.algorithm_tools);
    script7.src = `${process.env.PUBLIC_URL}/js/sfgj.js`;
    document.body.appendChild(script7);
  };
  const onError = (error: any) => {
    console.log(error);
  };
  useRequest(url, { method: "GET", onSuccess, onError });

  React.useEffect(() => {
    // 加载全部的脚本
    const script1 = document.createElement("script");
    script1.src = `${process.env.PUBLIC_URL}/js/jquery-1.11.0.min.js`;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);

    const script3 = document.createElement("script");
    script3.src = `${process.env.PUBLIC_URL}/js/common.js`;
    document.body.appendChild(script3);

    const script4 = document.createElement("script");
    script4.src = `${process.env.PUBLIC_URL}/js/component.min.js`;
    document.body.appendChild(script4);

    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    const script6 = document.createElement("script");
    script6.src = `${process.env.PUBLIC_URL}/js/swiper.js`;
    document.body.appendChild(script6);

    return () => {
      // 在组件卸载时移除脚本
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
      document.body.removeChild(script4);
      document.body.removeChild(script5);
      document.body.removeChild(script6);
      document.body.removeChild(script7);
    };
  }, []);

  return (
    <div className="home">
      <AboutUs />
      <canvas id="ribbon"></canvas>
      <div id="wrapper" style={{ display: "none" }}>
        <div className="page-content home-page">
          <ul id="bullets">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul id="pages">
            <li
              id="home1"
              className="home1 flex-xc"
              data-slug="home1"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#e79d40"
              data-selection-color="rgba(231,157,64,0.25)"
            ></li>
            <li
              id="home2"
              className="home2"
              data-slug="home2"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#338fb9"
              data-selection-color="rgba(51,143,185,0.25)"
            ></li>
          </ul>
        </div>
      </div>
      <div className="header">
        <div />
        <a href="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </a>
      </div>
      <div className="copyright">
        {/* <p>© 2023深原质药 All Rights Reserved.</p> */}
      </div>
      {/* <div className="links">
        <a href="">
          <img src={require("../../images/zh.svg").default} alt="" />
        </a>
        <a href="">
          <img src={require("../../images/wb.svg").default} alt="" />
        </a>
        <a>
          <img src={require("../../images/wx.svg").default} alt="" />
          <div className="code">
            <img src={require("../../images/code.png")} alt="" />
          </div>
        </a>
      </div> */}
      <QrcodeLink />
      <div className="section">
        <div className="menu">
          <Link to="/">首页 / </Link>
          <Link to="/medicine">AI制药深度空间 / </Link>
          <span>算法工具</span>
        </div>
        {/* <div className="t">算法工具产品</div> */}
        <div className="swiper">
          <div className="bg">
            <img src={require("../../images/sd.png")} alt="" />
          </div>
          <div className="swiper-container">
            <div className="swiper-wrapper">
              {data &&
                data.map((item: any) => (
                  <div className="swiper-slide">
                    <Link
                      className="item"
                      to={`https://${
                        process.env.REACT_APP_ENV === "production"
                          ? "console"
                          : "console-test"
                      }.dprotein.cn/algorithm/detail?id=${item.id}`}
                    >
                      <div className="text">
                        <p className="pt">{item.title}</p>
                        <p className="time">{item.time}</p>
                        <div className="des">{item.describe}</div>
                      </div>
                      <div className="pic">
                        <img src={item.cover} alt="" />
                        <div className="line"></div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Fling />
    </div>
  );
};

export default AlgorithmTools;
