import React from "react";
import { Helmet } from "react-helmet";
import "../../css/common.css";
import "../../css/rally.css";
import "../../css/tech.css";
import { Link } from "react-router-dom";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
const Intro: React.FC = () => {
  React.useEffect(() => {
    // 加载全部的脚本

    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);
    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    return () => {
      // 在组件卸载时移除脚本

      document.body.removeChild(script2);
      document.body.removeChild(script5);
    };
  }, []);

  return (
    <div className="homet">
      <Helmet>
        <title>深原质药</title>
      </Helmet>
      <AboutUs />
      <canvas id="ribbon"></canvas>
      <div id="wrapper" style={{ display: "none" }}>
        <div className="page-content home-page">
          <ul id="bullets">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul id="pages">
            <li
              id="home1"
              className="home1 flex-xc"
              data-slug="home1"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#e79d40"
              data-selection-color="rgba(231,157,64,0.25)"
            ></li>
            <li
              id="home2"
              className="home2"
              data-slug="home2"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#338fb9"
              data-selection-color="rgba(51,143,185,0.25)"
            ></li>
          </ul>
        </div>
      </div>
      <div className="header">
        <div />
        <a href="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </a>
      </div>
      <div className="copyright">
        {/* <p>© 2023深原质药 All Rights Reserved.</p> */}
      </div>
      {/* <div className="links">
        <a href="">
          <img src={require("../../images/zh.svg").default} alt="" />
        </a>
        <a href="">
          <img src={require("../../images/wb.svg").default} alt="" />
        </a>
        <a>
          <img src={require("../../images/wx.svg").default} alt="" />
          <div className="code">
            <img src={require("../../images/code.png")} alt="" />
          </div>
        </a>
      </div> */}
      <QrcodeLink />

      <div className="section">
        <div className="menu">
          <a href="/">首页 / </a>
          <a href="/medicine">AI制药深度空间 / </a>
          <span>AI制药101</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1584.366"
          height="1191.341"
          viewBox="0 0 1584.366 1191.341"
        >
          <g
            id="组_8051"
            data-name="组 8051"
            transform="translate(5094.366 8289.69)"
          >
            <g
              id="组_8030"
              data-name="组 8030"
              transform="translate(-5321 -8476.148)"
            >
              <rect
                id="矩形_291"
                data-name="矩形 291"
                width="8.544"
                height="125.313"
                transform="translate(651.717 1010.403)"
                fill="#fec158"
              />
              <rect
                id="矩形_293"
                data-name="矩形 293"
                width="8.544"
                height="125.313"
                transform="translate(651.717 759.777)"
                fill="#fec158"
              />
              <rect
                id="矩形_292"
                data-name="矩形 292"
                width="8.544"
                height="125.313"
                transform="translate(651.717 1135.717)"
                fill="#fec158"
              />
              <rect
                id="矩形_294"
                data-name="矩形 294"
                width="8.544"
                height="125.313"
                transform="translate(651.717 885.09)"
                fill="#fec158"
              />
              <g
                id="组_445"
                data-name="组 445"
                transform="translate(514.667 914.994)"
              >
                <rect
                  id="矩形_303"
                  data-name="矩形 303"
                  width="129.929"
                  height="9.968"
                  transform="translate(0)"
                  fill="#fec158"
                />
                <rect
                  id="矩形_304"
                  data-name="矩形 304"
                  width="22.834"
                  height="9.968"
                  transform="matrix(0.695, 0.719, -0.719, 0.695, 129.451, 0.362)"
                  fill="#fec158"
                />
              </g>
              <g
                id="组_446"
                data-name="组 446"
                transform="translate(520.707 1188.405)"
              >
                <rect
                  id="矩形_303-2"
                  data-name="矩形 303"
                  width="122.465"
                  height="9.968"
                  fill="#fec158"
                />
                <rect
                  id="矩形_304-2"
                  data-name="矩形 304"
                  width="22.834"
                  height="9.968"
                  transform="matrix(0.695, 0.719, -0.719, 0.695, 123.411, 0.362)"
                  fill="#fec158"
                />
              </g>
              <g
                id="组_463"
                data-name="组 463"
                transform="matrix(0.819, 0.574, -0.574, 0.819, 289.411, 1101.05)"
              >
                <rect
                  id="矩形_303-3"
                  data-name="矩形 303"
                  width="157.893"
                  height="9.968"
                  transform="translate(0 0)"
                  fill="#fec158"
                />
                <path
                  id="路径_54"
                  data-name="路径 54"
                  d="M0,0,17.546-5.106,15.317,2.709-2.229,7.815Z"
                  transform="matrix(0.695, 0.719, -0.719, 0.695, 158.838, 0.362)"
                  fill="#fec158"
                />
              </g>
              <g
                id="组_464"
                data-name="组 464"
                transform="matrix(0.819, 0.574, -0.574, 0.819, 399.025, 947.256)"
              >
                <rect
                  id="矩形_303-4"
                  data-name="矩形 303"
                  width="161.216"
                  height="9.968"
                  transform="translate(0 0)"
                  fill="#fec158"
                />
                <rect
                  id="矩形_304-3"
                  data-name="矩形 304"
                  width="22.834"
                  height="9.968"
                  transform="matrix(0.695, 0.719, -0.719, 0.695, 162.162, 0.362)"
                  fill="#fec158"
                />
              </g>
              <g
                id="组_447"
                data-name="组 447"
                transform="translate(651.717 829.339)"
              >
                <rect
                  id="矩形_303-5"
                  data-name="矩形 303"
                  width="189.394"
                  height="9.968"
                  transform="translate(15.383)"
                  fill="#fec158"
                />
                <rect
                  id="矩形_304-4"
                  data-name="矩形 304"
                  width="22.834"
                  height="9.968"
                  transform="matrix(-0.695, 0.719, -0.719, -0.695, 23.032, 7.286)"
                  fill="#fec158"
                />
              </g>
              <g
                id="组_458"
                data-name="组 458"
                transform="translate(571.972 1043.868)"
              >
                <rect
                  id="矩形_303-6"
                  data-name="矩形 303"
                  width="72.625"
                  height="9.968"
                  fill="#fec158"
                />
                <rect
                  id="矩形_304-5"
                  data-name="矩形 304"
                  width="22.834"
                  height="9.968"
                  transform="matrix(0.695, 0.719, -0.719, 0.695, 72.146, 0.362)"
                  fill="#fec158"
                />
              </g>
              <g
                id="组_460"
                data-name="组 460"
                // rel="noreferrer"
                transform="translate(655.989 1078.756)"
              >
                <rect
                  id="矩形_303-7"
                  data-name="矩形 303"
                  width="143.826"
                  height="9.968"
                  transform="translate(16.807)"
                  fill="#fec158"
                />
                <rect
                  id="矩形_304-6"
                  data-name="矩形 304"
                  width="22.834"
                  height="9.968"
                  transform="matrix(-0.695, 0.719, -0.719, -0.695, 23.032, 7.286)"
                  fill="#fec158"
                />
              </g>
              <path
                id="路径_53"
                data-name="路径 53"
                d="M66.217,0A66.217,66.217,0,1,1,0,66.217,66.217,66.217,0,0,1,66.217,0Z"
                transform="translate(590.484 1245.366)"
                fill="#dc8d05"
              />
              <text
                id="蛋白质_设计"
                data-name="蛋白质
设计"
                transform="translate(656.236 1302.542)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "20",
                }}
              >
                <tspan x="-33" y="0">
                  蛋白质
                </tspan>
                <tspan x="-22" y="29">
                  设计
                </tspan>
              </text>
              <path
                id="矩形_299"
                data-name="矩形 299"
                d="M0,0H118.433a14,14,0,0,1,14,14V52.306a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(403.938 1162.964)"
                fill="#e79b17"
              />
              <path
                id="矩形_329"
                data-name="矩形 329"
                d="M0,0H119.4a14,14,0,0,1,14,14V52.688a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(447.966 1016.101)"
                fill="#e79b17"
              />
              <path
                id="矩形_331"
                data-name="矩形 331"
                d="M0,0H104.639a14,14,0,0,1,14,14V46.857a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(817 1062.148)"
                fill="#e79b17"
              />
              <path
                id="矩形_332"
                data-name="矩形 332"
                d="M0,0H135.458a14,14,0,0,1,14,14V46.992a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(745.546 814.149)"
                fill="#e79b17"
              />
              <path
                id="矩形_330"
                data-name="矩形 330"
                d="M0,0H194.352a14,14,0,0,1,14,14V73.171a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(305.458 879.969)"
                fill="#e79b17"
              />
              <path
                id="矩形_333"
                data-name="矩形 333"
                d="M0,0H146.6a14,14,0,0,1,14,14V63.655a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(572 701.149)"
                fill="#e79b17"
              />
              <path
                id="矩形_328"
                data-name="矩形 328"
                d="M0,0H112.477a14,14,0,0,1,14,14V49.953a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(226.634 1060.311)"
                fill="#e79b17"
              />
              <text
                id="单点突变"
                transform="translate(469.298 1197.165)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-32" y="0">
                  单点突变
                </tspan>
              </text>
              <text
                id="模块搭建"
                transform="translate(513.811 1050.493)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-32" y="0">
                  模块搭建
                </tspan>
              </text>
              <text
                id="序列设计"
                transform="translate(876 1092.148)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-32" y="0">
                  序列设计
                </tspan>
              </text>
              <text
                id="从头设计"
                transform="translate(828.15 844.149)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-32" y="0">
                  从头设计
                </tspan>
              </text>
              <text
                id="基于RosettaDesign_的设计方法"
                data-name="基于RosettaDesign
的设计方法"
                transform="translate(408.225 909.514)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-74.281" y="0">
                  基于RosettaDesign
                </tspan>
                <tspan x="-40" y="21">
                  的设计方法
                </tspan>
              </text>
              <text
                id="基于电子密度的_蛋白质设计"
                data-name="基于电子密度的
蛋白质设计"
                transform="translate(652 728.148)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-56" y="0">
                  基于电子密度的
                </tspan>
                <tspan x="-40" y="21">
                  蛋白质设计
                </tspan>
              </text>
              <text
                id="定向进化"
                transform="translate(289.872 1091.788)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-32" y="0">
                  定向进化
                </tspan>
              </text>
            </g>
            <g
              id="组_8047"
              data-name="组 8047"
              transform="translate(-5554.5 -8593.75)"
            >
              <path
                id="矩形_340"
                data-name="矩形 340"
                d="M0,0H227a14,14,0,0,1,14,14V91.5a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(1293.5 435.75)"
                fill="#03167c"
              />
              <text
                id="基于扩散模型的_从头设计"
                data-name="基于扩散模型的
从头设计"
                transform="translate(1413.5 472.75)"
                fill="#fff"
                style={{
                  fontSize: "22",
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  // fontWeight: "600",
                }}
              >
                <tspan x="-84" y="0">
                  基于扩散模型的
                </tspan>
                <tspan x="-48" y="34">
                  从头设计
                </tspan>
              </text>
            </g>
            <line
              id="直线_42"
              data-name="直线 42"
              x1="12"
              y1="369.75"
              transform="translate(-4682.5 -8145.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_43"
              data-name="直线 43"
              y1="273.5"
              x2="40"
              transform="translate(-4670.5 -8048.5)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_48"
              data-name="直线 48"
              x1="305"
              y2="206"
              transform="translate(-4630.5 -8253.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_35"
              data-name="直线 35"
              x1="67"
              y2="225"
              transform="translate(-4442.5 -7638.5)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_38"
              data-name="直线 38"
              x1="102"
              y1="335.25"
              transform="translate(-4477.5 -7973.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_41"
              data-name="直线 41"
              y1="339.25"
              x2="294"
              transform="translate(-4493.5 -8066.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_40"
              data-name="直线 40"
              y2="66"
              transform="translate(-4493.5 -7727.5)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_49"
              data-name="直线 49"
              x1="236"
              y1="52"
              transform="translate(-4682.5 -8145.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_45"
              data-name="直线 45"
              x2="396"
              y2="120"
              transform="translate(-4446.5 -8093.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_47"
              data-name="直线 47"
              y1="212"
              x2="84"
              transform="translate(-4343.5 -8226.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_44"
              data-name="直线 44"
              x2="203"
              y2="153.25"
              transform="translate(-4343.5 -8014.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_39"
              data-name="直线 39"
              x1="152"
              y1="304"
              transform="translate(-4477.5 -7973.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_36"
              data-name="直线 36"
              x2="108"
              y2="135"
              transform="translate(-4325.5 -7669.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_46"
              data-name="直线 46"
              x2="132"
              y2="93"
              transform="translate(-4182.5 -8066.75)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <line
              id="直线_37"
              data-name="直线 37"
              x1="214"
              y1="286"
              transform="translate(-4140.5 -7861.5)"
              fill="none"
              stroke="#03167c"
              style={{ strokeWidth: "2" }}
            />
            <rect
              id="矩形_483"
              data-name="矩形 483"
              width="6"
              height="88"
              transform="translate(-3983 -7545.25)"
              fill="#c8beff"
            />
            <rect
              id="矩形_491"
              data-name="矩形 491"
              width="6"
              height="88"
              transform="translate(-3983 -7721.25)"
              fill="#c8beff"
            />
            <rect
              id="矩形_497"
              data-name="矩形 497"
              width="6"
              height="151.5"
              transform="translate(-3983 -7960.75)"
              fill="#c8beff"
            />
            <rect
              id="矩形_484"
              data-name="矩形 484"
              width="6"
              height="88"
              transform="translate(-3983 -7457.25)"
              fill="#c8beff"
            />
            <rect
              id="矩形_486"
              data-name="矩形 486"
              width="6"
              height="88"
              transform="translate(-3983 -7633.25)"
              fill="#c8beff"
            />
            <rect
              id="矩形_495"
              data-name="矩形 495"
              width="6"
              height="88"
              transform="translate(-3983 -7809.25)"
              fill="#c8beff"
            />
            <g
              id="组_8035"
              data-name="组 8035"
              transform="translate(-231 10878.75)"
            >
              <rect
                id="矩形_303-8"
                data-name="矩形 303"
                width="190"
                height="7"
                transform="translate(-3948 -18404)"
                fill="#c8beff"
              />
              <rect
                id="矩形_304-7"
                data-name="矩形 304"
                width="16.035"
                height="7"
                transform="matrix(0.695, 0.719, -0.719, 0.695, -3757.336, -18403.746)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8036"
              data-name="组 8036"
              transform="translate(-230.803 10795.75)"
            >
              <rect
                id="矩形_303-9"
                data-name="矩形 303"
                width="108"
                height="7"
                transform="translate(-3866 -18404)"
                fill="#c8beff"
              />
              <rect
                id="矩形_304-8"
                data-name="矩形 304"
                width="16.035"
                height="7"
                transform="matrix(0.695, 0.719, -0.719, 0.695, -3757.336, -18403.746)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8032"
              data-name="组 8032"
              transform="translate(-231 10973.75)"
            >
              <rect
                id="矩形_303-10"
                data-name="矩形 303"
                width="101"
                height="7"
                transform="translate(-3859 -18404)"
                fill="#c8beff"
              />
              <rect
                id="矩形_304-9"
                data-name="矩形 304"
                width="16.035"
                height="7"
                transform="matrix(0.695, 0.719, -0.719, 0.695, -3757.336, -18403.746)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8038"
              data-name="组 8038"
              transform="matrix(0.695, 0.719, -0.719, 0.695, -14758.781, 7815.961)"
            >
              <rect
                id="矩形_303-11"
                data-name="矩形 303"
                width="72"
                height="4"
                transform="translate(-3798.872 -18387.549) rotate(11)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8040"
              data-name="组 8040"
              transform="matrix(0.191, 0.982, -0.982, 0.191, -21539.859, -563.664)"
            >
              <rect
                id="矩形_303-12"
                data-name="矩形 303"
                width="72"
                height="4"
                transform="translate(-3798.872 -18387.549) rotate(11)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8041"
              data-name="组 8041"
              transform="translate(-21342.326 -946.198) rotate(81)"
            >
              <rect
                id="矩形_303-13"
                data-name="矩形 303"
                width="72"
                height="4"
                transform="translate(-3798.872 -18387.549) rotate(11)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8042"
              data-name="组 8042"
              transform="matrix(-0.669, 0.743, -0.743, -0.669, -19851.713, -17192.135)"
            >
              <path
                id="路径_48"
                data-name="路径 48"
                d="M.424,11.676,150.708-3.541,149.863.623-.421,15.84Z"
                transform="translate(-3796.199 -18399.092) rotate(11)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8033"
              data-name="组 8033"
              transform="translate(-3983 -7491.9)"
            >
              <rect
                id="矩形_303-14"
                data-name="矩形 303"
                width="101"
                height="7"
                transform="translate(11.803)"
                fill="#c8beff"
              />
              <rect
                id="矩形_304-10"
                data-name="矩形 304"
                width="16.035"
                height="7"
                transform="matrix(-0.695, 0.719, -0.719, -0.695, 16.174, 5.117)"
                fill="#c8beff"
              />
            </g>
            <path
              id="矩形_492"
              data-name="矩形 492"
              d="M0,0H133a22,22,0,0,1,22,22V68a0,0,0,0,1,0,0H22A22,22,0,0,1,0,46V0A0,0,0,0,1,0,0Z"
              transform="translate(-3838 -7370.25)"
              fill="#8e83c7"
            />
            <text
              id="基于概率统计模型_GMMs_HMMs_"
              data-name="基于概率统计模型
(GMMs, HMMs)"
              transform="translate(-3760 -7339.25)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-64" y="0">
                基于概率统计模型
              </tspan>
              <tspan x="-64.398" y="21">
                (GMMs, HMMs)
              </tspan>
            </text>
            <g
              id="组_8031"
              data-name="组 8031"
              transform="translate(11647.5 14480.168)"
            >
              <path
                id="矩形_327"
                data-name="矩形 327"
                d="M0,0H133a22,22,0,0,1,22,22V68a0,0,0,0,1,0,0H22A22,22,0,0,1,0,46V0A0,0,0,0,1,0,0Z"
                transform="translate(-15705 -21853)"
                fill="#8e83c7"
              />
              <text
                id="深度学习_Deep_NeuralNetwork_"
                data-name="深度学习(Deep
NeuralNetwork)"
                transform="translate(-15627 -21822)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-58.473" y="0">
                  深度学习(Deep{" "}
                </tspan>
                <tspan x="-65.066" y="21">
                  NeuralNetwork)
                </tspan>
              </text>
            </g>
            <path
              id="矩形_482"
              data-name="矩形 482"
              d="M0,0H183a14,14,0,0,1,14,14V69a0,0,0,0,1,0,0H14A14,14,0,0,1,0,55V0A0,0,0,0,1,0,0Z"
              transform="translate(-4283 -7462.25)"
              fill="#8e83c7"
            />
            <path
              id="矩形_485"
              data-name="矩形 485"
              d="M0,0H128a14,14,0,0,1,14,14V47a0,0,0,0,1,0,0H14A14,14,0,0,1,0,33V0A0,0,0,0,1,0,0Z"
              transform="translate(-4298 -7547.25)"
              fill="#8e83c7"
            />
            <path
              id="矩形_494"
              data-name="矩形 494"
              d="M0,0H171a14,14,0,0,1,14,14V54.5a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
              transform="translate(-4298 -7735.75)"
              fill="#8e83c7"
            />
            <path
              id="矩形_496"
              data-name="矩形 496"
              d="M0,0H103a14,14,0,0,1,14,14V39.5a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
              transform="translate(-4274 -7838.25)"
              fill="#8e83c7"
            />
            <path
              id="矩形_488"
              data-name="矩形 488"
              d="M0,0H171a14,14,0,0,1,14,14V54.5a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
              transform="translate(-4217 -7633.25)"
              fill="#8e83c7"
            />
            <path
              id="矩形_490"
              data-name="矩形 490"
              d="M0,0H171a14,14,0,0,1,14,14V54.5a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
              transform="translate(-3870 -7511.25)"
              fill="#8e83c7"
            />
            <text
              id="循环神经网络_RNN_LSTM_GRU_"
              data-name="循环神经网络(RNN)
(LSTM, GRU)"
              transform="translate(-4184 -7434.25)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-73.391" y="0">
                循环神经网络(RNN)
              </tspan>
              <tspan x="-51.238" y="26">
                (LSTM, GRU)
              </tspan>
            </text>
            <text
              id="_3D-CNN"
              data-name="3D-CNN"
              transform="translate(-4227 -7519.25)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-33.715" y="0">
                3D-CNN
              </tspan>
            </text>
            <text
              id="变分自编码器_VAE_"
              data-name="变分自编码器(VAE)"
              transform="translate(-4205 -7702.75)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-70.09" y="0">
                变分自编码器(VAE)
              </tspan>
            </text>
            <g
              id="组_8046"
              data-name="组 8046"
              transform="translate(-5341.5 -8487.75)"
            >
              <path
                id="矩形_340-2"
                data-name="矩形 340"
                d="M0,0H171a14,14,0,0,1,14,14V54.5a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(1269 472.5)"
                fill="#8e83c7"
              />
              <text
                id="扩散模型_Diffusion_"
                data-name="扩散模型(Diffusion)"
                transform="translate(1362 505.5)"
                fill="#fff"
                style={{
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  fontSize: "16",
                }}
              >
                <tspan x="-75.359" y="0">
                  扩散模型(Diffusion)
                </tspan>
              </text>
            </g>
            <text
              id="VQ-VAE"
              transform="translate(-4215 -7814.25)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-31.594" y="0">
                VQ-VAE
              </tspan>
            </text>
            <text
              id="自编码器_AE_UNet_"
              data-name="自编码器(AE, UNet)"
              transform="translate(-4124 -7600.25)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-74.457" y="0">
                自编码器(AE, UNet)
              </tspan>
            </text>
            <text
              id="注意力机制_Attention_"
              data-name="注意力机制(Attention)"
              transform="translate(-3778 -7478.25)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-84.641" y="0">
                注意力机制(Attention)
              </tspan>
            </text>
            <g
              id="组_8039"
              data-name="组 8039"
              transform="translate(-3982.803 -7690.332)"
            >
              <rect
                id="矩形_303-15"
                data-name="矩形 303"
                width="101"
                height="7"
                transform="translate(11.803)"
                fill="#c8beff"
              />
              <rect
                id="矩形_304-11"
                data-name="矩形 304"
                width="16.035"
                height="7"
                transform="matrix(-0.695, 0.719, -0.719, -0.695, 16.174, 5.117)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8043"
              data-name="组 8043"
              transform="translate(-3983 -7846.574)"
            >
              <rect
                id="矩形_303-16"
                data-name="矩形 303"
                width="101"
                height="7"
                transform="translate(11.803)"
                fill="#c8beff"
              />
              <rect
                id="矩形_304-12"
                data-name="矩形 304"
                width="16.035"
                height="7"
                transform="matrix(-0.695, 0.719, -0.719, -0.695, 16.174, 5.117)"
                fill="#c8beff"
              />
            </g>
            <path
              id="矩形_493"
              data-name="矩形 493"
              d="M0,0H150.5a14,14,0,0,1,14,14V64.25a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
              transform="translate(-3923 -7718.75)"
              fill="#8e83c7"
            />
            <text
              id="生成对抗网络_GANs_"
              data-name="生成对抗网络
(GANs)"
              transform="translate(-3841 -7692.75)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-48" y="0">
                生成对抗网络
              </tspan>
              <tspan x="-29.105" y="26">
                (GANs)
              </tspan>
            </text>
            <path
              id="矩形_498"
              data-name="矩形 498"
              d="M0,0H184a14,14,0,0,1,14,14V66a0,0,0,0,1,0,0H14A14,14,0,0,1,0,52V0A0,0,0,0,1,0,0Z"
              transform="translate(-3946.5 -7871.25)"
              fill="#8e83c7"
            />
            <path
              id="矩形_499"
              data-name="矩形 499"
              d="M0,0H184a14,14,0,0,1,14,14V66a0,0,0,0,1,0,0H14A14,14,0,0,1,0,52V0A0,0,0,0,1,0,0Z"
              transform="translate(-3734.359 -7775.75)"
              fill="#8e83c7"
            />
            <text
              id="归一化流_Normalizing_Flows_"
              data-name="归一化流(Normalizing
Flows)"
              transform="translate(-3841 -7844.25)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-84.758" y="0">
                归一化流(Normalizing
              </tspan>
              <tspan x="-25.969" y="26">
                Flows)
              </tspan>
            </text>
            <text
              id="多模态任务_CLIP_"
              data-name="多模态任务(CLIP)"
              transform="translate(-3635.359 -7736.75)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-63.938" y="0">
                多模态任务(CLIP)
              </tspan>
            </text>
            <g
              id="组_8044"
              data-name="组 8044"
              transform="matrix(-0.985, 0.174, -0.174, -0.985, -10609.453, -25025.004)"
            >
              <rect
                id="矩形_303-17"
                data-name="矩形 303"
                width="72"
                height="4"
                transform="translate(-3798.872 -18387.549) rotate(11)"
                fill="#c8beff"
              />
            </g>
            <path
              id="矩形_500"
              data-name="矩形 500"
              d="M0,0H184a14,14,0,0,1,14,14V66a0,0,0,0,1,0,0H14A14,14,0,0,1,0,52V0A0,0,0,0,1,0,0Z"
              transform="translate(-3708 -7612)"
              fill="#8e83c7"
            />
            <text
              id="基于人类反馈的强化学习_RLHF_ChatGPT_"
              data-name="基于人类反馈的强化学习
(RLHF, ChatGPT)"
              transform="translate(-3609 -7585)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-88" y="0">
                基于人类反馈的强化学习
              </tspan>
              <tspan x="-66.16" y="26">
                (RLHF, ChatGPT)
              </tspan>
            </text>
            <path
              id="矩形_487"
              data-name="矩形 487"
              d="M0,0H184a14,14,0,0,1,14,14V66a0,0,0,0,1,0,0H14A14,14,0,0,1,0,52V0A0,0,0,0,1,0,0Z"
              transform="translate(-3933.928 -7613.251)"
              fill="#8e83c7"
            />
            <text
              id="Transformer架构_BERT_GPT"
              data-name="Transformer架构
(BERT / GPT)"
              transform="translate(-3834.928 -7586.251)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "16",
              }}
            >
              <tspan x="-65.301" y="0">
                Transformer架构
              </tspan>
              <tspan x="-48.504" y="26">
                (BERT / GPT)
              </tspan>
            </text>
            <rect
              id="矩形_489"
              data-name="矩形 489"
              width="38.91"
              height="6"
              transform="translate(-3878.5 -7206.25) rotate(-90)"
              fill="#c7beff"
            />
            <circle
              id="椭圆_186"
              data-name="椭圆 186"
              cx="66"
              cy="66"
              r="66"
              transform="translate(-3946 -7232)"
              fill="#7569b2"
            />
            <text
              id="生成式AI"
              transform="translate(-3880 -7158)"
              fill="#fff"
              style={{
                fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                fontSize: "20",
              }}
            >
              <tspan x="-44.526" y="0">
                生成式AI
              </tspan>
            </text>
            <g
              id="组_8037"
              data-name="组 8037"
              transform="matrix(0.819, 0.574, -0.574, 0.819, -3920.719, -7296.838)"
            >
              <path
                id="路径_51"
                data-name="路径 51"
                d="M0,0,121.269,26.716l2.257,7.2L2.257,7.2Z"
                transform="matrix(0.454, -0.891, 0.891, 0.454, 64.439, 17.347)"
                fill="#c8beff"
              />
              <rect
                id="矩形_307"
                data-name="矩形 307"
                width="17"
                height="6"
                transform="translate(145.316 -73.996) rotate(-126)"
                fill="#c8beff"
              />
              <ellipse
                id="椭圆_171"
                data-name="椭圆 171"
                cx="7"
                cy="7"
                rx="7"
                ry="7"
                transform="translate(125.726 -94.483) rotate(-36)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8034"
              data-name="组 8034"
              transform="matrix(-0.819, 0.574, -0.574, -0.819, -3859.328, -7233.054)"
            >
              <path
                id="路径_51-2"
                data-name="路径 51"
                d="M.111,33.973l116.6-21.457L116.6,6.093,2.359,26.768Z"
                transform="matrix(0.454, 0.891, -0.891, 0.454, 30.22, 0)"
                fill="#c8beff"
              />
              <rect
                id="矩形_307-2"
                data-name="矩形 307"
                width="17"
                height="6"
                transform="matrix(-0.588, 0.809, -0.809, -0.588, 77.851, 106.845)"
                fill="#c8beff"
              />
              <ellipse
                id="椭圆_171-2"
                data-name="椭圆 171"
                cx="7"
                cy="7"
                rx="7"
                ry="7"
                transform="matrix(0.809, 0.588, -0.588, 0.809, 61.635, 112.479)"
                fill="#c8beff"
              />
            </g>
            <g
              id="组_8048"
              data-name="组 8048"
              transform="translate(-6048.252 -8679.846)"
            >
              <path
                id="矩形_340-3"
                data-name="矩形 340"
                d="M0,0H166a14,14,0,0,1,14,14V75a0,0,0,0,1,0,0H14A14,14,0,0,1,0,61V0A0,0,0,0,1,0,0Z"
                transform="translate(1292.096 468.897)"
                fill="#03167c"
              />
              <text
                id="CUTEDGE"
                transform="translate(1382.096 514.897)"
                fill="#fff"
                style={{
                  fontSize: "22",
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  // fontWeight: "600",
                }}
              >
                <tspan x="-58.092" y="0">
                  CUTEDGE
                </tspan>
              </text>
            </g>
            <g
              id="组_8049"
              data-name="组 8049"
              transform="translate(-5626 -8762.19)"
            >
              <path
                id="矩形_340-4"
                data-name="矩形 340"
                d="M0,0H230a14,14,0,0,1,14,14V71.881a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(1269 472.5)"
                fill="#03167c"
              />
              <text
                id="分子设计通用模型"
                transform="translate(1391.249 515.603)"
                fill="#fff"
                style={{
                  fontSize: "22",
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  // fontWeight: "600",
                }}
              >
                <tspan x="-96" y="0">
                  分子设计通用模型
                </tspan>
              </text>
            </g>
            <g
              id="组_8045"
              data-name="组 8045"
              transform="translate(-5842.392 -8521)"
            >
              <path
                id="矩形_340-5"
                data-name="矩形 340"
                d="M0,0H220.784a14,14,0,0,1,14,14V99.75a0,0,0,0,1,0,0H14a14,14,0,0,1-14-14V0A0,0,0,0,1,0,0Z"
                transform="translate(1242.392 473)"
                fill="#03167c"
              />
              <text
                id="基于3D-CNN的_序列设计"
                data-name="基于3D-CNN的
序列设计"
                transform="translate(1359.392 513.999)"
                fill="#fff"
                style={{
                  fontSize: "22",
                  fontFamily: "MicrosoftYaHei, Microsoft YaHei",
                  // fontWeight: "600",
                }}
              >
                <tspan x="-85.452" y="0">
                  基于3D-CNN的
                </tspan>
                <tspan x="-48" y="34">
                  序列设计
                </tspan>
              </text>
            </g>
          </g>
        </svg>

        <Fling />
      </div>
    </div>
  );
};

export default Intro;
