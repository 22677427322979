import React, { useState } from "react";
import { Pagination } from "antd";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../css/common.css";
import "../../css/cases.css";
import { Link } from "react-router-dom";
import useRequest from "../../api/api";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
const Intro: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page_num, setPageNum] = useState(
    Number(searchParams.get("page_num")) || 1
  );
  const [total, setTotal] = useState(0);
  const url = `/api/case/list?page_num=${page_num}&page_size=10`;
  const [data, setData] = useState([]);
  const onSuccess = (res: any) => {
    setData(res.data.cases);
    setTotal(res.data.total);
  };
  const onError = (error: any) => {
    console.log(error);
  };
  useRequest(url, { method: "GET", onSuccess, onError });

  function pageChange(page: any) {
    setSearchParams({ page_num: page });
  }

  React.useEffect(() => {
    // 加载全部的脚本
    const script1 = document.createElement("script");
    script1.src = `${process.env.PUBLIC_URL}/js/jquery-1.11.0.min.js`;
    document.body.appendChild(script1);

    const script3 = document.createElement("script");
    script3.src = `${process.env.PUBLIC_URL}/js/common.js`;
    document.body.appendChild(script3);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script3);
    };
  }, []);

  return (
    <div className="homec">
      <AboutUs />
      <div className="header">
        <div />
        <a href="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </a>
        {/* <Link to="/about" className="about">
          <span className="circle"></span>
          <p>关于我们</p>
        </Link> */}
      </div>
      <div className="copyright">
        {/* <p>© 2023深原质药 All Rights Reserved.</p> */}
      </div>

      <QrcodeLink />

      <div className="section">
        <div className="menu">
          <a href="/">首页 / </a>
          <a href="/medicine">AI制药深度空间 / </a>
          <span>实战案例</span>
        </div>
        <div className="list">
          {data &&
            data.map((item: any) => (
              <Link to={`/case_detail?id=${item.id}`} target="_blank">
                <div className="text">
                  <p className="pt">{item.title}</p>
                  <p className="des">{item.describe}</p>
                  <div className="tag">
                    <p>查看更多</p>
                    <img
                      src={require("../../images/right.svg").default}
                      alt=""
                    />
                  </div>
                </div>
                <div className="pic">
                  <img src={item.cover} alt="" />
                </div>
              </Link>
            ))}
          {data && total <= 0 && (
            <div className="nodata">
              <p>敬请期待！</p>
              <img src={require("../../images/nodata.png")} alt="暂无数据" />
            </div>
          )}
        </div>
        {data && total > 0 && (
          <Pagination
            current={page_num}
            total={total}
            pageSize={10}
            onChange={pageChange}
          />
        )}
      </div>
      <Fling />
    </div>
  );
};

export default Intro;
