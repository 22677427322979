import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FloatButton, Tooltip } from "antd";
import { SmileOutlined } from "@ant-design/icons";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>

  <div>
    <a href="/feedback" target="_blank">
      <Tooltip placement="left" title="给我们反馈">
        <FloatButton
          icon={<SmileOutlined />}
          type="primary"
          style={{ right: 24 }}
        />
      </Tooltip>
    </a>
    <App />
  </div>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
