import React from "react";
import "./index.css";
export default function FlingTwo() {
  return (
    <div className="fl-two">
      <div className="fl-box">
        <span>深原质药 ©2024</span>
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          京ICP备2023011317号
        </a>
        <div className="pl">
          {/* <img className="fl-icon" src={require("../../images/ga.jpg")} /> */}
          <a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=11011402053595"
            rel="noreferrer"
            target="_blank"
          >
            京公网安备11011402053595
          </a>
        </div>
      </div>
    </div>
  );
}
