import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import "@wangeditor/editor/dist/css/style.css";
import "../../css/common.css";
import "../../css/rally.css";
import "../../css/info.css";
import { Link } from "react-router-dom";
import useRequest from "../../api/api";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
const Detail: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const url = `/api/solution/detail?id=1`;
  const [title, setTitle] = useState("");
  const [context, setContext] = useState("");
  const [time, setTime] = useState("");
  const onSuccess = (res: any) => {
    setTitle(res.data.title);
    setContext(res.data.context);
    setTime(res.data.time);
  };
  const onError = (error: any) => {
    console.log(error);
  };
  useRequest(url, { method: "GET", onSuccess, onError });

  React.useEffect(() => {
    // 加载全部的脚本
    document.title = "深原质药_蛋白质/多肽的设计及优化服务";
    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);

    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    return () => {
      // 在组件载时移除脚本
      document.body.removeChild(script2);
      document.body.removeChild(script5);
    };
  }, []);

  return (
    <div className="homei">
      <AboutUs />
      <canvas id="ribbon"></canvas>
      <div id="wrapper" style={{ display: "none" }}>
        <div className="page-content home-page">
          <ul id="bullets">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul id="pages">
            <li
              id="home1"
              className="home1 flex-xc"
              data-slug="home1"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#e79d40"
              data-selection-color="rgba(231,157,64,0.25)"
            ></li>
            <li
              id="home2"
              className="home2"
              data-slug="home2"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#338fb9"
              data-selection-color="rgba(51,143,185,0.25)"
            ></li>
          </ul>
        </div>
      </div>
      <div className="header">
        <div />
        <Link to="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </Link>
      </div>
      <div className="copyright">
        {/* <p>© 2023深原质药 All Rights Reserved.</p> */}
      </div>

      <QrcodeLink />
      <div className="section">
        <div className="title">{title}</div>
        <div className="time">{time}</div>
        <div className="html">
          <div
            className="wangEditor-container"
            dangerouslySetInnerHTML={{ __html: context }}
          ></div>
          <div className="article-end">
            <div />
            <span>END</span>
            <div />
          </div>
        </div>
      </div>
      <Fling />
    </div>
  );
};

export default Detail;
