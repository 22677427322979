import React from "react";

export default function QrcodeLink() {
  return (
    <div className="links">
      <a>
        <img src={require("../../images/zh.svg").default} alt="" />
        <div className="code">
          <img
            src="https://public-dprotein-1317696360.cos.ap-beijing.myqcloud.com/1280X1280.PNG"
            alt=""
          />
        </div>
      </a>
      <a>
        <img src={require("../../images/wb.svg").default} alt="" />
        <div className="code">
          <img
            src="https://public-dprotein-1317696360.cos.ap-beijing.myqcloud.com/d791cf72-ca9a-4f41-8383-ea881644dff4.png"
            alt=""
          />
        </div>
      </a>
      <a>
        <img src={require("../../images/wx.svg").default} alt="" />
        <div className="code">
          <img
            src="https://public-dprotein-1317696360.cos.ap-beijing.myqcloud.com/d6eab287-fce3-464e-8876-f326f1305eb3.jpg"
            alt=""
          />
        </div>
      </a>
    </div>
  );
}
