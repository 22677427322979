import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../../css/swiper.css";
import "../../css/common.css";
import "../../css/rally.css";
import "../../css/intro.css";
import "./index.css";
import { Link } from "react-router-dom";
import { Avatar, Card, Typography, Tag, Space } from "antd";
import useRequest from "../../api/api";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
import { MailOutlined } from "@ant-design/icons";
const { Meta } = Card;
const { Title } = Typography;
const Team: React.FC = () => {
  const url = "/api/enterprise/info";
  const [title, titleData] = useState("");
  const [team, setTeam] = useState([]);
  const [manage, setManage] = useState([]);
  const [adviser, setAdviser] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [email, setEamil] = useState("");

  const script7 = document.createElement("script");
  const onSuccess = (res: any) => {
    titleData(res.data.enterprise.intro);
    setEamil(res.data.enterprise.email);
    setTeam(res.data.team);
    setManage(res.data.manage);
    setAdviser(res.data.adviser);
    setJobs(res.data.recruitment.jobs);

    script7.src = `${process.env.PUBLIC_URL}/js/team.js`;
    document.body.appendChild(script7);
  };
  const onError = (error: any) => {
    console.log(error);
  };
  useRequest(url, { method: "GET", onSuccess, onError });
  React.useEffect(() => {
    // 加载全部的脚本

    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);

    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    const script8 = document.createElement("script");
    script8.src = `https://webapi.amap.com/maps?v=1.4.15&key=water_web`;
    document.body.appendChild(script8);

    // 在组件卸载时移除脚本和地图
    return () => {
      document.body.removeChild(script8);
      document.body.removeChild(script7);
    };
  }, []);
  return (
    <div className="homein team">
      <AboutUs />
      <canvas id="ribbon"></canvas>
      <div id="wrapper" style={{ display: "none" }}>
        <div className="page-content home-page">
          <ul id="bullets">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul id="pages">
            <li
              id="home1"
              className="home1 flex-xc"
              data-slug="home1"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#e79d40"
              data-selection-color="rgba(231,157,64,0.25)"
            ></li>
            <li
              id="home2"
              className="home2"
              data-slug="home2"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#338fb9"
              data-selection-color="rgba(51,143,185,0.25)"
            ></li>
          </ul>
        </div>
      </div>
      <div className="header">
        <div />
        <a href="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </a>
      </div>
      <div className="copyright"></div>

      <QrcodeLink />

      <div className="section">
        {adviser && (
          <div className="honor advisor-team" id="page1">
            <div className="t">
              <span>科学顾问委员会</span>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="people-list">
                {adviser.map((item: any, index: number) => {
                  return (
                    <div className="swiper-slide" key={`${index}-adviser`}>
                      <div className="info">
                        <Card
                          hoverable
                          cover={
                            <div className="advisor">
                              <div className="user">
                                <Avatar
                                  style={{ height: "120px", width: "90px" }}
                                  shape="square"
                                  src={item.avatar}
                                />
                                <div className="name">
                                  <span>{item.name}</span>
                                  <span className="job-title">
                                    {item.identity}
                                  </span>
                                </div>
                              </div>
                              <div className="tags">
                                {item.tags &&
                                  item.tags.map(
                                    (sitem: any, sindex: number) => {
                                      return (
                                        <Tag key={`${sindex}-tags`}>
                                          {sitem}
                                        </Tag>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          }
                        >
                          <Meta
                            title=""
                            description={
                              <div style={{ height: "110px" }}>{item.more}</div>
                            }
                          />
                        </Card>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {/* {manage && (
          <div className="honor" id="page2">
            <div className="t">
              <span>管理团队</span>
            </div>

            <div className="people-list">
              {manage.map((item: any) => (
                <div className="swiper-slide">
                  <div className="info">
                    <Card
                      hoverable
                      cover={
                        <div className="advisor">
                          <div className="user">
                            <Avatar size={100} src={item.avatar} />
                            <div className="name">
                              <span>{item.name}</span>

                              <span className="job-title">{item.identity}</span>
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <Meta
                        style={{ minHeight: "140px" }}
                        title=""
                        description={item.more}
                      />
                    </Card>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )} */}
        {team && (
          <div className="honor" id="page2">
            <div className="t">
              <span>管理团队与核心成员</span>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="people-list">
                {team.slice(0, 2).map((item: any) => (
                  <div className="swiper-slide">
                    <div className="info">
                      <Card
                        hoverable
                        cover={
                          <div className="advisor">
                            <div className="user">
                              <Avatar size={100} src={item.avatar} />
                              <div className="name">
                                <span>{item.name}</span>
                                <span className="job-title">
                                  {item.identity}
                                </span>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <Meta
                          style={{ minHeight: "140px" }}
                          title=""
                          description={item.more}
                        />
                      </Card>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ height: "40px" }} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="people-list">
                {team.slice(2, 5).map((item: any) => (
                  <div className="swiper-slide">
                    <div className="info">
                      <Card
                        hoverable
                        cover={
                          <div className="advisor">
                            <div className="user">
                              <Avatar size={100} src={item.avatar} />
                              <div className="name">
                                <span>{item.name}</span>
                                <span className="job-title">
                                  {item.identity}
                                </span>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <Meta
                          style={{ minHeight: "140px" }}
                          title=""
                          description={item.more}
                        />
                      </Card>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="prev">
              <img src={require("../../images/jt.svg").default} alt="" />
            </div>
            <div className="next">
              <img src={require("../../images/jt.svg").default} alt="" />
            </div> */}
          </div>
        )}
      </div>

      {false && (
        <div className="join" id="page3">
          <div className="join-box">
            <div className="t">
              <span>人才招聘</span>
            </div>
            <div className="content">
              <div className="allItems">
                {jobs.map((item: any) => (
                  <div className="item">
                    <div className="d1">
                      <p className="name">{item.title} </p>
                      <p className="tag"></p>
                      <p className="tag"></p>
                      <p className="tag"></p>
                      <div className="icon">
                        <img
                          src={require("../../images/jt2.svg").default}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="d2">
                      <div className="info">
                        <div className="sj">
                          <p className="pt">【工作职责】</p>
                          <div style={{ display: "flex" }} className="sj-box">
                            <MailOutlined />
                            <p className="sj-emali">简历投递：hr@dprotein.cn</p>
                          </div>
                        </div>

                        <div
                          className="des"
                          dangerouslySetInnerHTML={{
                            __html: item.responsibility,
                          }}
                        ></div>
                      </div>
                      <div className="info">
                        <p className="pt">【工作要求】</p>
                        <div
                          className="des"
                          dangerouslySetInnerHTML={{ __html: item.require }}
                        ></div>
                      </div>
                      <a
                        href={`mailto:${email}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>立即投递</p>
                        <img
                          src={require("../../images/right.svg").default}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <Fling />
    </div>
  );
};

export default Team;
