import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../../css/intro.css";
import "./index.css";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
import { Form, Button, Input, Upload, message } from "antd";
import Api from "../../utils/http/request";
interface Ret {
  code: number;
  msg: string;
  data: any;
}
const Intro: React.FC = () => {
  const script7 = document.createElement("script");
  script7.src = `${process.env.PUBLIC_URL}/js/intro.js`;
  document.body.appendChild(script7);

  const onReply = async (params: any) => {
    let resp: Ret = await Api.post("/api/user/reply", params);
    console.log("=====", resp);
    if (resp.code === 0) {
      message.info("反馈成功", 2.5);
    } else {
      message.warning("反馈失败");
    }
  };
  React.useEffect(() => {
    // 加载全部的脚本

    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);

    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    const script8 = document.createElement("script");
    script8.src = `https://webapi.amap.com/maps?v=1.4.15&key=water_web`;
    document.body.appendChild(script8);

    // 在组件卸载时移除脚本和地图
    return () => {
      document.body.removeChild(script8);
      document.body.removeChild(script7);
    };
  }, []);
  return (
    <div className="homein company">
      <Helmet>
        <title>深原质药_用户反馈</title>
      </Helmet>
      <AboutUs />
      <canvas id="ribbon"></canvas>
      <div id="wrapper" style={{ display: "none" }}>
        <div className="page-content home-page">
          <ul id="bullets">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul id="pages">
            <li
              id="home1"
              className="home1 flex-xc"
              data-slug="home1"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#e79d40"
              data-selection-color="rgba(231,157,64,0.25)"
            ></li>
            <li
              id="home2"
              className="home2"
              data-slug="home2"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#338fb9"
              data-selection-color="rgba(51,143,185,0.25)"
            ></li>
          </ul>
        </div>
      </div>
      <div className="header">
        <div />
        <a href="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </a>
      </div>
      <div className="copyright">
        {/* <p>© 2023深原质药 All Rights Reserved.</p> */}
      </div>

      <QrcodeLink />

      <div className="section contact">
        <div className="t">
          <span className="t-pre"></span>
          <span>给我们反馈</span>
        </div>
        <div style={{ height: "24px" }} />
        <div className="intro">
          <div>
            <Form title="问题反馈" layout="vertical" onFinish={onReply}>
              <Form.Item
                label="联系方式（请确保联系方式是否填写正确，会影响结果的发送）:"
                required
                name="phone"
              >
                <Input />
              </Form.Item>
              <Form.Item label="信息填写:" required name="context">
                <Input.TextArea style={{ height: "160px" }} />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ width: "120px" }}
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

      <div className="contact">
        <div className="t">
          <span className="t-pre"></span>
          <span>联系我们</span>
        </div>
        <div className="content">
          <div className="l">
            <div className="dom1">
              <div className="it" style={{ width: "85%" }}>
                <p className="pt">加入我们</p>
                <p className="tag">hr@dprotein.cn</p>
              </div>

              <div className="it" style={{ width: "85%" }}>
                <p className="pt">技术咨询</p>
                <p className="tag">it@dprotein.cn</p>
              </div>
              <div className="it" style={{ width: "85%" }}>
                <p className="pt">商务合作</p>
                <p className="tag">sapiens@dprotein.cn</p>
              </div>
              <div className="it" style={{ width: "85%" }}>
                <p className="pt">联系电话</p>
                <p className="tag">13810503314</p>
              </div>
              <div className="it" style={{ width: "85%" }}>
                <p className="pt">联系地址</p>
                <p className="tag">
                  北京市昌平区生命科学园生命园路29号创新大厦B座1层2层
                </p>
              </div>
            </div>
            {/* <div className="pic">
              <img src={require("../../images/house.jpg")} alt="" />
            </div> */}
          </div>
          <div className="map" id="allmap"></div>
        </div>
        <div className="botm-pic">
          <img src={require("../../images/of.jpg")} alt="" />
        </div>
      </div>

      <Fling />
    </div>
  );
};

export default Intro;
