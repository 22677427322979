import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../../css/common.css";
import "../../css/rally.css";
import "../../css/index.css";
import "./index.less";
import { Link, useNavigate } from "react-router-dom";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
import useRequest from "../../api/api";

const Index: React.FC = () => {
  const navigate = useNavigate();
  const url = "/api/home/index";
  const [data, setData] = useState([]);

  const onSuccess = (res: any) => {
    const menus = res.data.menus;
    setData(menus);
  };
  const onError = (error: any) => {
    console.log(error);
  };
  useRequest(url, { method: "GET", onSuccess, onError });

  React.useEffect(() => {
    // 加载脚本

    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);
    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    return () => {
      //在组件卸载时移除脚本
      document.body.removeChild(script2);
      document.body.removeChild(script5);
    };
  }, []);
  return (
    <>
      <div className="home">
        <Helmet>
          <title>深原质药</title>
        </Helmet>
        <canvas id="ribbon"></canvas>
        <div id="wrapper" style={{ display: "none" }}>
          <div className="page-content home-page">
            <ul id="bullets">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <ul id="pages">
              <li
                id="home1"
                className="home1 flex-xc"
                data-slug="home1"
                data-primarycolor="#f3f3f3"
                data-secondarycolor="#ebebeb"
                data-midcolor="#e79d40"
                data-selection-color="rgba(231,157,64,0.25)"
              ></li>
              <li
                id="home2"
                className="home2"
                data-slug="home2"
                data-primarycolor="#f3f3f3"
                data-secondarycolor="#ebebeb"
                data-midcolor="#338fb9"
                data-selection-color="rgba(51,143,185,0.25)"
              ></li>
            </ul>
          </div>
        </div>
        <AboutUs />
        <div className="header">
          <div />
          <a href="/">
            <img
              src={require("../../images/logo.png")}
              alt=""
              style={{ height: "56px" }}
            />
          </a>
        </div>
        <div className="copyright">
          {/* <p>© 2023深原质药 All Rights Reserved.</p> */}
        </div>
        <QrcodeLink />
        <div className="section1">
          {data &&
            data.map((item: any, num: any) => {
              return (
                <div
                  className="item"
                  style={{ cursor: `${item.state === 1 ? "pointer" : "auto"}` }}
                  key={item.id}
                  onClick={() => {
                    window.TriggerEvent(1000, item.id);
                    if (item.state === 1) {
                      if (item.link) {
                        window.location.href = item.link;
                      }
                    }
                  }}
                >
                  <img
                    src={item.cover && item.state === 1 ? item.cover : ""}
                    alt=""
                  />
                  <div className="info">
                    <div className="des">
                      {item.state === 1 ? item.describe : ""}
                    </div>
                  </div>

                  <div className={`new ${item.style.title_class}`}>
                    <span>{item.state === 1 ? item.title : ""}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <Fling />
      </div>
    </>
  );
};

export default Index;
