import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

interface CmsRequestConfig extends InternalAxiosRequestConfig {
  headers: any;
}

let hostUrl = "/";
if (process.env.REACT_APP_ENV === "production") {
  hostUrl = "https://api.dprotein.cn";
} else if (process.env.REACT_APP_ENV === "development") {
  hostUrl = "https://api-test.dprotein.cn";
}
const Api = axios.create({
  baseURL: hostUrl,
  withCredentials: true,
  timeout: 6000,
});

Api.interceptors.request.use(
  (config: CmsRequestConfig) => {
    const token = localStorage.getItem("X-Access-Token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

Api.interceptors.response.use(
  (resp: AxiosResponse<any>) => {
    return Promise.resolve(resp.data);
  },
  (error: AxiosError<any>) => {
    const message = error.message.includes("timeout")
      ? "请求超时"
      : error.response?.data?.message ?? `错误码 ${error.response?.status}`;

    window.location.href = "/";

    return Promise.reject(error);
  }
);

export default Api;
