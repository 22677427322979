import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../../css/swiper.css";
import "../../css/common.css";
import "../../css/rally.css";
import "../../css/intro.css";
import "./index.css";
import { Link } from "react-router-dom";
import useRequest from "../../api/api";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
const Intro: React.FC = () => {
  const url = "/api/enterprise/info";
  const [title, titleData] = useState("");
  const [data, setData] = useState([]);
  const [team, setteam] = useState([]);
  const [jobs, setjobs] = useState([]);
  const [email, seteamil] = useState("");
  const [totalPeople, settotalPeople] = useState(0);
  const [totalPositions, settotalPositions] = useState(0);

  const script7 = document.createElement("script");
  const onSuccess = (res: any) => {
    titleData(res.data.enterprise.intro);
    seteamil(res.data.enterprise.email);
    setData(res.data.honor);
    setteam(res.data.team);
    setjobs(res.data.recruitment.jobs);
    settotalPeople(res.data.recruitment.total_people);
    settotalPositions(res.data.recruitment.total_positions);
    console.log(res.data, 123);
    script7.src = `${process.env.PUBLIC_URL}/js/intro.js`;
    document.body.appendChild(script7);
  };
  const onError = (error: any) => {
    console.log(error);
  };
  useRequest(url, { method: "GET", onSuccess, onError });
  React.useEffect(() => {
    // 加载全部的脚本

    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);

    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    const script8 = document.createElement("script");
    script8.src = `https://webapi.amap.com/maps?v=1.4.15&key=water_web`;
    document.body.appendChild(script8);

    // 在组件卸载时移除脚本和地图
    return () => {
      document.body.removeChild(script8);
      document.body.removeChild(script7);
    };
  }, []);
  return (
    <div className="homein company">
      <Helmet>
        <title>深原质药</title>
      </Helmet>
      <AboutUs />
      <canvas id="ribbon"></canvas>
      <div id="wrapper" style={{ display: "none" }}>
        <div className="page-content home-page">
          <ul id="bullets">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul id="pages">
            <li
              id="home1"
              className="home1 flex-xc"
              data-slug="home1"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#e79d40"
              data-selection-color="rgba(231,157,64,0.25)"
            ></li>
            <li
              id="home2"
              className="home2"
              data-slug="home2"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#338fb9"
              data-selection-color="rgba(51,143,185,0.25)"
            ></li>
          </ul>
        </div>
      </div>
      <div className="header">
        <div />
        <a href="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </a>
      </div>
      <div className="copyright">
        {/* <p>© 2023深原质药 All Rights Reserved.</p> */}
      </div>

      <QrcodeLink />

      <div className="section">
        <div className="intro">
          <img src={require("../../images/bg.jfif")} alt="" />
          <div className="text" style={{ textAlign: "left" }}>
            <p className="pt"></p>
            <div className="des">
              <p>{title}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="contact">
        <div className="t">
          <span className="t-pre"></span>
          <span>联系我们</span>
        </div>
        <div className="content">
          <div className="l">
            <div className="dom1">
              <div className="it">
                <p className="pt">加入我们</p>
                <p className="tag">{email || "hr@dprotein.cn"}</p>
              </div>

              <div className="it">
                <p className="pt">技术咨询</p>
                <p className="tag">{email || "it@dprotein.cn"}</p>
              </div>
              <div className="it">
                <p className="pt">商务合作</p>
                <p className="tag">{email || "sapiens@dprotein.cn"}</p>
              </div>
              <div className="it" style={{ width: "85%" }}>
                <p className="pt">联系电话</p>
                <p className="tag">13810503314</p>
              </div>
              <div className="it" style={{ width: "85%" }}>
                <p className="pt">联系地址</p>
                <p className="tag">
                  北京市昌平区生命科学园生命园路29号创新大厦B座1层2层
                </p>
              </div>
            </div>
          </div>
          <div className="map" id="allmap"></div>
        </div>

        <div className="botm-pic">
          <img src={require("../../images/of.jpg")} alt="" />
        </div>
      </div>

      <Fling />
    </div>
  );
};

export default Intro;
