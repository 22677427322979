import React from "react";
import { Helmet } from "react-helmet";
import "../../css/common.css";
import "../../css/rally.css";
import "../../css/zy.css";
import { Link } from "react-router-dom";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
const Medicine: React.FC = () => {
  React.useEffect(() => {
    // 加载全部的脚本
    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);
    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    return () => {
      // 在组件卸载时移除脚本
      document.body.removeChild(script2);
      document.body.removeChild(script5);
    };
  }, []);

  return (
    <div className="homezy">
      <AboutUs />
      <canvas id="ribbon"></canvas>
      <div id="wrapper" style={{ display: "none" }}>
        <div className="page-content home-page">
          <ul id="bullets">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul id="pages">
            <li
              id="home1"
              className="home1 flex-xc"
              data-slug="home1"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#e79d40"
              data-selection-color="rgba(231,157,64,0.25)"
            ></li>
            <li
              id="home2"
              className="home2"
              data-slug="home2"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#338fb9"
              data-selection-color="rgba(51,143,185,0.25)"
            ></li>
          </ul>
        </div>
      </div>
      <div className="header">
        <div />
        <a href="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </a>
      </div>
      <div className="copyright"></div>

      <QrcodeLink />
      <div className="section">
        <div className="d1">
          <Link className="item" to="/solution">
            <img className="bg" src={require("../../images/a6.jpg")} alt="" />
            <div className="tag">
              <p>解决方案</p>
            </div>
          </Link>
          <Link className="item" to="/">
            <img
              className="bg"
              src={require("../../images/aitoolf.jpg")}
              alt=""
            />
            <div className="tag">
              <p>算法工具</p>
            </div>
          </Link>
          <Link className="item" to="/case">
            <img className="bg" src={require("../../images/a8.jpg")} alt="" />
            <div className="tag">
              <p>实战案例</p>
            </div>
          </Link>
        </div>
        <div className="d2">
          <Link className="item" to="/news">
            <img className="bg" src={require("../../images/a3.jpg")} alt="" />
            <div className="tag">
              <p>前沿动态</p>
            </div>
          </Link>
          <Link className="item" to="/tech">
            <img className="bg" src={require("../../images/101.jpg")} alt="" />
            <div className="tag">
              <p>AI制药101</p>
            </div>
          </Link>
        </div>
      </div>
      <div className="section2">
        <div className="list">
          <div className="item">
            <div className="aList">{/* <a href="">Vail Resorts</a> */}</div>
          </div>
          <div className="item">
            <div className="aList">{/* <a href="">Vail Resorts</a> */}</div>
          </div>
          <div className="item">
            <div className="aList">{/* <a href="">Vail Resorts</a> */}</div>
          </div>
          <div className="item">
            <div className="aList">{/* <a href="">Vail Resorts</a> */}</div>
          </div>
        </div>
      </div>
      <Fling />
    </div>
  );
};

export default Medicine;
