import React, { useState } from "react";
import "./tutorial.css";

const Index: React.FC = () => {
  React.useEffect(() => {
    window.TriggerEvent("123");
    return () => {};
  }, []);

  return (
    <div className="tutorial">
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <div className="title-box">
            <span className="title">深原平台使用说明</span>
            <span className="title-subscript">
              (以下以CUTEDGEopt为例进行说明)
            </span>
          </div>

          <p>
            <h3>1、进入官网点击对应算法</h3>
            <img className="tec-img" src={require("../../images/tu01.jpg")} />
          </p>

          <p>
            <h3>2、算法使用</h3>
            <img className="tec-img" src={require("../../images/tu02.jpg")} />
          </p>
          <p>
            <h3>3、用户登录</h3>
            <img className="tec-img" src={require("../../images/02.jpg")} />
          </p>

          <p>
            <h3>4、用户注册</h3>
            <img className="tec-img" src={require("../../images/03.jpg")} />
          </p>
          <p>
            <h3>5、返回算法主页</h3>
            <img className="tec-img" src={require("../../images/04.jpg")} />
          </p>

          <p>
            <h3>6、等待结果</h3>
            <img className="tec-img" src={require("../../images/07.jpg")} />
          </p>
          <p>
            <h3>8、查看结果</h3>
            <img className="tec-img" src={require("../../images/08.jpg")} />
          </p>

          <p>
            <h3>9、结果内容解释</h3>
            <iframe
              src="//player.bilibili.com/player.html?aid=917677934&bvid=BV1Yu4y1Y7rR&cid=1312805397&p=1"
              scrolling="no"
              style={{ border: 0, height: "600px", width: "800px" }}
            ></iframe>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Index;
