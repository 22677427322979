import React, { useState } from "react";
import { Pagination } from "antd";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../css/common.css";
import "../../css/rally.css";
import "../../css/news.css";
import { Link } from "react-router-dom";
import useRequest from "../../api/api";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
const Intro: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page_num, setPageNum] = useState(
    Number(searchParams.get("page_num")) || 1
  );
  const [total, setTotal] = useState(0);

  const url = `/api/news/list?page_num=${page_num}&page_size=10`;
  const [data, setData] = useState([]);

  const onSuccess = (res: any) => {
    setData(res.data.news);
    setTotal(res.data.total);
    console.log(res, 123);
  };
  const onError = (error: any) => {
    console.log(error);
  };
  useRequest(url, { method: "GET", onSuccess, onError });

  function pageChange(page: any) {
    setSearchParams({ page_num: page });
  }

  React.useEffect(() => {
    // 加载全部的脚本
    const script2 = document.createElement("script");
    script2.src = `${process.env.PUBLIC_URL}/js/rally.min.js`;
    document.body.appendChild(script2);
    const script5 = document.createElement("script");
    script5.src = `${process.env.PUBLIC_URL}/js/home1.js`;
    document.body.appendChild(script5);

    return () => {
      // 在组件卸载时移除脚本
      document.body.removeChild(script2);
      document.body.removeChild(script5);
    };
  }, []);

  return (
    <div className="homen">
      <AboutUs />
      <canvas id="ribbon"></canvas>
      <div id="wrapper" style={{ display: "none" }}>
        <div className="page-content home-page">
          <ul id="bullets">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <ul id="pages">
            <li
              id="home1"
              className="home1 flex-xc"
              data-slug="home1"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#e79d40"
              data-selection-color="rgba(231,157,64,0.25)"
            ></li>
            <li
              id="home2"
              className="home2"
              data-slug="home2"
              data-primarycolor="#f3f3f3"
              data-secondarycolor="#ebebeb"
              data-midcolor="#338fb9"
              data-selection-color="rgba(51,143,185,0.25)"
            ></li>
          </ul>
        </div>
      </div>
      <div className="header">
        <div />
        <Link to="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </Link>
      </div>
      <div className="copyright"></div>
      <QrcodeLink />

      <div className="section">
        <div className="menu">
          <Link to="/medicine">AI制药深度空间 / </Link>
          <span>前沿动态</span>
        </div>
        {/* <div className="t1">前沿动态</div> */}
        <div className="list">
          {data &&
            data.map((item: any) => (
              <Link
                to={`/news_detail?id=${item.id}`}
                target="_blank"
                key={item.id}
              >
                <div className="pic">
                  <img src={item.cover} alt="" />
                </div>
                <div className="text" style={{ textAlign: "left" }}>
                  <p className="pt">{item.title}</p>
                  <p className="des">{item.describe}</p>
                  <div className="tag">
                    <p className="time">{item.time}</p>
                    <div className="icon">
                      <img
                        src={require("../../images/jt2.svg").default}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Link>
            ))}

          {data && total <= 0 && (
            <div className="nodata">
              <p>敬请期待！</p>
              <img src={require("../../images/nodata.png")} alt="暂无数据" />
            </div>
          )}
        </div>
        {data && total > 0 && (
          <Pagination
            current={page_num}
            total={total}
            pageSize={10}
            onChange={pageChange}
          />
        )}
        <Fling />
      </div>
    </div>
  );
};

export default Intro;
