import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { useEffect } from "react";

import Index from "./pages/index/index";
import About from "./pages/about/index";
import Intro from "./pages/intro/index";
import News from "./pages/news/index";
import NewsDetail from "./pages/news/detail";
import Info from "./pages/info/index";
import Solution from "./pages/solution/index";
import SolutionDetail from "./pages/solution/detail";
import Case from "./pages/case/index";
import CaseDetail from "./pages/case/detail";
import Tech from "./pages/tech/index";
import AlgorithmTool from "./pages/algorithm_tool/index";
import Medicine from "./pages/medicine/index";
import Tutorial from "./pages/tutorial/index";
import Team from "./pages/team/index";
import Feedback from "./pages/feedback/index";
import Recruitment from "./pages/recruitment/index";

function App() {
  useEffect(() => {
    // 加载全部的脚本
    return () => {};
  }, []);

  return (
    <div className="App">
      <ConfigProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Index />} />
            {/* <Route path="/" element={<Index />} /> */}
            <Route path="/about" element={<About />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/news" element={<News />} />
            <Route path="/news_detail" element={<NewsDetail />} />
            <Route path="/info" element={<Info />} />
            <Route path="/algorithm_tool" element={<AlgorithmTool />} />
            <Route path="/solution" element={<SolutionDetail />} />
            <Route path="/case" element={<Case />} />
            <Route path="/case_detail" element={<CaseDetail />} />
            <Route path="/medicine" element={<Medicine />} />
            <Route path="/tech" element={<Tech />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/team" element={<Team />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/recruitment" element={<Recruitment />} />
          </Routes>
        </Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
