import React from "react";
import "../../css/common.css";
import "../../css/about.css";
import "../../less/about.less";
import { Link } from "react-router-dom";
import QrcodeLink from "../../components/qrcode/index";
import Fling from "../../components/fling/index";
import AboutUs from "../../components/us/index";
const About: React.FC = () => {
  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="home">
      <AboutUs />
      <div className="header">
        <div />
        <a href="/">
          <img
            src={require("../../images/logo.png")}
            alt=""
            style={{ height: "56px" }}
          />
        </a>
      </div>
      <div className="copyright">
        {/* <p>© 2023深原质药 All Rights Reserved.</p> */}
      </div>

      <QrcodeLink />

      <div className="section">
        <div className="d1">
          <Link className="item" to="/intro">
            <img
              className="bg"
              src="https://public-dprotein-1317696360.cos.ap-beijing.myqcloud.com/web%2F2023110gywm4-105139.jpg"
              alt=""
            />
            <div className="tag">
              <p>公司简介</p>
            </div>
          </Link>
          <Link className="item" to="/team?type=0">
            <img className="bg" src={require("../../images/a1.jpg")} alt="" />
            <div className="tag">
              <p>科学顾问</p>
            </div>
          </Link>

          <Link className="item" to="/team?type=1">
            <img className="bg" src={require("../../images/team.png")} alt="" />
            <div className="tag">
              <p>团队成员</p>
            </div>
          </Link>
          <Link className="item" to="/news">
            <img className="bg" src={require("../../images/a4.jpg")} alt="" />
            <div className="tag">
              <p>新闻中心</p>
            </div>
          </Link>
        </div>
        <div className="d2">
          <Link className="item" to="/recruitment">
            <img className="bg" src={require("../../images/a5.jpg")} alt="" />
            <div className="tag">
              <p>人才招聘</p>
            </div>
          </Link>
          <Link className="item" to="/solution">
            <img className="bg" src={require("../../images/a6.jpg")} alt="" />
            <div className="tag">
              <p>解决方案</p>
            </div>
          </Link>
          <Link className="item" to="/">
            <img
              className="bg"
              src={require("../../images/aitoolf.jpg")}
              alt=""
            />
            <div className="tag">
              <p>算法工具</p>
            </div>
          </Link>
          <Link className="item" to="/case">
            <img className="bg" src={require("../../images/a8.jpg")} alt="" />
            <div className="tag">
              <p>合作伙伴</p>
            </div>
          </Link>
        </div>
      </div>
      <Fling />
    </div>
  );
};

export default About;
