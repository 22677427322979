import React from "react";
import "./index.css";
export default function AboutUs() {
  return (
    <div className="about-us">
      <a href="/about" className="about">
        <span className="circle"></span>
        <span className="tx">关于我们</span>
      </a>
    </div>
  );
}
