import React, { useEffect, useState } from "react";

const useRequest = (url: any, options: any = {}) => {
  const token = localStorage.getItem("X-Access-Token");
  const {
    headers = { Authorization: `Bearer ${token}` },
    body,
    method = "GET",
    onSuccess,
    onError,
  } = options;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is still mounted

    const fetchData = async () => {
      try {
        let env = process.env.REACT_APP_ENV;

        if (env === "production") {
          url = `https://api.dprotein.cn${url}`;
        } else if (env === "development") {
          url = `https://api-test.dprotein.cn${url}`;
        }
        const response = await fetch(url, {
          method,
          headers,
          body: JSON.stringify(body),
        });

        if (!isMounted) {
          // If component is unmounted, abort the request
          return;
        }

        if (!response.ok) {
          throw new Error(`网络请求错误，状态码: ${response.status}`);
        }

        const data = await response.json();
        if (onSuccess) {
          onSuccess(data);
        }
      } catch (error) {
        if (onError) {
          onError(error);
          //window.location.href = "/";
        }
      } finally {
        setIsLoading(false); // Stop loading regardless of success or error
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Clean up function to abort request when component is unmounted
    };
  }, []);

  return isLoading; // Return loading state to the component
};

export default useRequest;
